import React from 'react'
import remark from 'remark'
import remarkHTML from 'remark-html'
import remarkParse from 'remark-parse'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import { ContainerSVGT, MainFont, EventsColor } from '../components/Sampler'
import { xmarkSVGT1, xmarkSVGT2 } from '../../static/svgtext'
import Content, { HTMLContent } from '../components/Content'

export const EventsPageTemplate = ({
  backgroundimage,
  events,
  contentComponent
}) => {
  const PageContent = contentComponent || Content
  const toHTML = value => remark()
    .use(remarkParse)
    .use(remarkHTML)
    .processSync(value)
    .toString()

  return (
    <div>
      <div
        className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage
            })`,
        }}
      >

        {/* Google Fonts Import */}
        <link
          href="https://fonts.googleapis.com/css2?family=IM+Fell+English&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
          rel="stylesheet"
        ></link>
        <link href="https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;700;900&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet"></link>
        <link href="https://fonts.googleapis.com/css2?family=Playfair+Display+SC:wght@400&display=swap" rel="stylesheet"></link>

        {/* EVENTS IMAGE HEADING */}
        <div style={{ display: 'grid' }}>

          {/* Colored Style Container */}
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="310px" viewBox="0 0 1563.000000 1525.000000"
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}>

            <g transform="translate(0.000000,-25.000000) scale(0.100000,0.100000)"
              fill={EventsColor} stroke="none">
              <path d={ContainerSVGT} />
            </g>
          </svg>

          {/* "Events" Text */}
          <div style={{
            gridRow: '1 / 1',
            gridColumn: '1 / 1',
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: '3.2em',
            color: '#FFFFFF',
            fontFamily: MainFont,
            fontWeight: MainFont === 'IM Fell English' || MainFont === 'Permanent Marker' ? null : 'bold',
            letterSpacing: MainFont === 'Dosis' ? '0.14em' : MainFont === 'Playfair Display SC' || MainFont === 'Bahnschrift' ? '0.05em' : null
          }}>
            EVENTS
          </div>
        </div>
      </div>

      {/* EVENT LIST */}
      { (events !== null && events.length > 0) ?
        <section className='section'>
          <div className='container'>
            <div className='content'>
              <div className='columns is-multiline'>
                {events.map((item) => (
                  <div className='event-pane-box'>
                    <h1 className="title is-size-3 has-text-weight-bold is-bold-light"
                      style={{
                        marginTop: '0px',
                        marginBottom: '5px',
                        color: EventsColor,
                        
                        // fontSize: '2.4em',
                        // color: 'white',
                        // fontFamily: 'IM Fell English'
                      }}
                    >
                      {item.name}
                    </h1>
                    <div className='event-pane'>
                      <div style={{
                        minWidth: '300px',
                      }}>
                        <p className='event-pane-date' style={{fontWeight: 'normal', fontSize: '20px', marginBottom: '10px'}}>{item.date} {item.time === null ? null : item.time}</p>
                        {item.image === null || item.image === undefined ? <div/> : 
                          <img className='event-pane-image' src={item.image.childImageSharp ? item.image.childImageSharp.fluid.src : item.image} alt="Image" />
                        }
                      </div>

                      <div className='event-pane-text'>
                        <PageContent className="content" content={toHTML(item.description)} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section >
      :
        <div className='no-events' style={{
          color: EventsColor
        }}>
          {console.log(events)}
          {/* Google Fonts Import */}
          <link
            href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
            rel="stylesheet"
          ></link >
          <svg className="x-mark" version="1.0" xmlns="http://www.w3.org/2000/svg"
            height="710.000000pt" viewBox="0 0 709 710" 
            preserveAspectRatio="xMidYMid meet"
            style={{
              gridRow: '1 / 1',
              gridColumn: '1 / 1',
            }}
          >
            <g transform="translate(0.000000,710.000000) scale(0.100000,-0.100000)"
              fill={EventsColor} stroke="none">
              <path d={xmarkSVGT1} />
              <path d={xmarkSVGT2} />
            </g>
          </svg>
          <p>
            There are no<br></br>events to show<br></br>at this time.<br></br><br></br>Stay tuned.
          </p>
        </div>
      }
    </div>
  )
}

EventsPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  events: PropTypes.array,
  contentComponent: PropTypes.func,
}

const EventsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <EventsPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        events={frontmatter.events}
        contentComponent={HTMLContent}
      />
    </Layout>
  )
}

EventsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default EventsPage

export const EventsPageQuery = graphql`
  query EventsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "events-page" } }) {
      html
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        events {
          name
          date
          time
          image {
            childImageSharp {
              fluid(maxWidth: 1075, quality: 72) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          description
        }
      }
    }
  }
`